<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/configuracoes/usuarios"
  >
    <h4 class="text-center">Dados básicos</h4>
    <LineDivider></LineDivider>
    <FormField
      label="Nome do usuário"
      v-model="form.nome"
      :validation="$v.form.nome"
    />
    <FormField
      label="E-mail do usuário"
      v-model="form.email"
      typeField="email"
      :validation="$v.form.email"
    />
    <p>Lembre-se de ajustar as permissões desse usuário.</p>
    <p>
      O usuário receberá instruções de acesso pelo e-mail informado após alguns
      minutos.
    </p>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import { mapGetters } from 'vuex'
import { USUARIO_NEW } from '@/store/actions/usuario'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'UsuarioForm',
  components: {
    SidePopup,
    LineDivider,
    FormButton,
    FormField
  },
  data: () => ({
    isLoading: false,
    form: {}
  }),
  validations: {
    form: {
      nome: { required },
      email: { required, email }
    }
  },
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/configuracoes/usuarios')
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.isLoading = true
      const data = this.prepareData()
      this.$store
        .dispatch(USUARIO_NEW, data)
        .then(() => {
          this.$vueOnToast.pop('success', 'Novo usuário criado com sucesso!')
          this.onClose(true)
        })
        .catch(err => {
          const erro = err.response.data.error || 'Erro ao criar novo usuário'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    prepareData() {
      return {
        nome: this.form.nome,
        email: this.form.email,
        empresa: this.getEmpresaId
      }
    }
  },
  computed: {
    ...mapGetters(['getEmpresaId']),
    title() {
      return 'Novo usuário'
    }
  }
}
</script>
